<template>
  <ion-page>
    <!-- <Header name="Anmelden" /> -->
    <ion-content :fullscreen="true">
      <ion-icon 
        class="close-to-home" 
        :icon="close" 
        color="light"
        @click.prevent="goHome()"
      />
      <div id="container" :style="{ backgroundImage: 'url(' + require('@/assets/img/skyline.svg') + ')' }">
        <div>
          <h1>UKK <span class="font-face--brand">Aktuell</span></h1>
          <p class="subline text-color--lightgrey">Registrieren Sie sich mit Ihrer E-Mail-Adresse der Uniklinik Köln. Bitte verwenden Sie <span class="emphasize">nicht</span> Ihr Windows-Passwort.</p>
        </div>

        <div 
          v-if="submitted" 
          class="submitted"
          :class="{'success': !hasError}"
        >
          <p v-if="hasError">Fehler bei der Registrierung.</p>
          <p v-else>Wir haben Ihnen eine E-Mail geschickt. Bitte folgen Sie den Anweisungen in der E-Mail, um Ihren Account zu aktivieren.</p>
        </div>

        <form v-else id="signup" v-on:submit.prevent="submit()">
          <input type="email" name="email" v-model="email" class="email" placeholder="max.mustermann@uk-koeln.de">
          <input type="password" name="password" v-model="password" class="pwd" placeholder="Passwort">
          <input type="password" name="passwordConfirm" v-model="passwordConfirm" class="pwd" placeholder="Passwort wiederholen">
          <div 
            v-if="hasFormErrors"
            class="validation-errors"
          >
            <ul>
              <li
                v-for="(validationError, index) in formErrors" 
                :key="index"
              >
                {{ validationError.message }}
              </li>
            </ul>
          </div>
          <button @click.prevent="submit()" class="btn-next" type="submit" form="signup" value="Registrieren">
            <span>Registrieren</span>
          </button>
        </form>

        <div>
          <p style="margin-top: 0" class="blur-bg subline text-color--lightgrey">Haben Sie bereits ein Konto?<br>Bitte <router-link to="/auth/login">melden Sie sich an</router-link>.</p>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonContent } from '@ionic/vue';
import { close } from 'ionicons/icons'

// @ts-ignore
import * as api from '@/service/index.js'

export default  {
  name: 'Signup',
  components: { 
    IonContent, 
    IonPage 
  },
  data () {
    return {
      email: '',
      password: '',
      passwordConfirm: '',
      sent: false,
      registrationError: false,
      formErrors: [],
      close
    }
  },
  computed: {
    submitted (): boolean {
      return (this as any).sent;
    },
    hasError (): boolean {
      return (this as any).registrationError;
    },
    hasFormErrors (): boolean {
      return (this as any).formErrors.length
    }
  },
  methods: {
    goHome () {
      (this as any).$router.push('/');
    },
    validateForm () {
      const vm = (this as any);

      vm.formErrors = []
      let formValid = true

      // check email rules
      if (!vm.email.length) {
        formValid = false
        vm.formErrors.push({
          field: 'email',
          message: 'E-Mail ist ein Pflichtfeld.'
        })
      } else {
        // check if it's a valid email
        let input = document.createElement('input')
        input.type = 'email'
        input.required = true
        input.value = vm.email

        let validEmail = typeof input.checkValidity === 'function' ? input.checkValidity() : /\S+@\S+\.\S+/.test(vm.email)
        if (!validEmail) {
          formValid = false
          vm.formErrors.push({
            field: 'email',
            message: 'Die E-Mail-Adresse ist ungültig.'
          })
        } else {
          // check if it meets the required email format (https://jira.sunzinet.com/browse/WIRD-403)
          let account, host, valid
          [ account, host ] = vm.email.split('@')

          valid = /\S+\.\S+/.test(account)
          if (!valid) {
            formValid = false
            vm.formErrors.push({
              field: 'email',
              message: 'Die E-Mail-Adresse entspricht nicht dem erforderlichen Format: vorname.nachname@uk-koeln.de.'
            })
          }
        }
      }

      // check password rules
      if (!vm.password.length) {
        formValid = false
        vm.formErrors.push({
          field: 'password',
          message: 'Passwort ist ein Pflichtfeld.'
        })
      }
      if (!vm.passwordConfirm.length) {
        formValid = false
        vm.formErrors.push({
          field: 'passwordConfirm',
          message: 'Passwort-Wiederholung ist ein Pflichtfeld.'
        })
      }
      if (vm.password.length && vm.passwordConfirm.length && vm.password !== vm.passwordConfirm) {
        formValid = false
        vm.formErrors.push({
          field: 'passwordConfirm',
          message: 'Passwort-Wiederholung stimmt nicht mit Passwort überein.'
        })
      }

      return formValid
    },
    async submit () {
      const vm = (this as any);

      let formValid = vm.validateForm()

      if (formValid) {
        console.log('form valid')
        api.signup(vm.email, vm.password, vm.passwordConfirm)
          .then((result: any) => {
            if (result.status === 201) {
              vm.sent = true;
            } 
          })
          .catch((error: any) => {
            console.log(error);
            vm.sent = true;
            vm.registrationError = true;
          });
      } else {
        console.log('form errors', vm.formErrors)
      }
    }
  },
  ionViewWillLeave () {
    console.log('[ionView] will leave signup');
    (this as any).sent = false;
    (this as any).registrationError = false;
  },
  ionViewDidEnter () {
    console.log('[ionView] entered signup');
  }
}
</script>

<style scoped lang="less">
.submitted p {
  background: lightcoral;
  border-radius: 3px;
  padding: 15px;
  margin: 5px 17px;
  color: #fff;
  font-weight: bold;
  line-height: 24px;
}

.submitted.success p {
  background: lightseagreen;
}

.lost-password {
  font-size: 13px;
  text-align: right;
  color: var(--text-color-blue);
  text-decoration: none;
}
.emphasize {
  font-weight: bold;
  color: lightcoral;
}

.auth #container div.validation-errors {
  background: lightcoral;
  border-radius: 3px;
  padding: 15px;
  margin: 5px 17px;
  color: #fff;
  font-weight: bold;
  line-height: 24px;
  text-align: left;
  width: auto;

  ul {
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 20px;
  }
}
</style>