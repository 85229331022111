
import { IonPage, IonContent } from '@ionic/vue';
import { close } from 'ionicons/icons'

// @ts-ignore
import * as api from '@/service/index.js'

export default  {
  name: 'Signup',
  components: { 
    IonContent, 
    IonPage 
  },
  data () {
    return {
      email: '',
      password: '',
      passwordConfirm: '',
      sent: false,
      registrationError: false,
      formErrors: [],
      close
    }
  },
  computed: {
    submitted (): boolean {
      return (this as any).sent;
    },
    hasError (): boolean {
      return (this as any).registrationError;
    },
    hasFormErrors (): boolean {
      return (this as any).formErrors.length
    }
  },
  methods: {
    goHome () {
      (this as any).$router.push('/');
    },
    validateForm () {
      const vm = (this as any);

      vm.formErrors = []
      let formValid = true

      // check email rules
      if (!vm.email.length) {
        formValid = false
        vm.formErrors.push({
          field: 'email',
          message: 'E-Mail ist ein Pflichtfeld.'
        })
      } else {
        // check if it's a valid email
        let input = document.createElement('input')
        input.type = 'email'
        input.required = true
        input.value = vm.email

        let validEmail = typeof input.checkValidity === 'function' ? input.checkValidity() : /\S+@\S+\.\S+/.test(vm.email)
        if (!validEmail) {
          formValid = false
          vm.formErrors.push({
            field: 'email',
            message: 'Die E-Mail-Adresse ist ungültig.'
          })
        } else {
          // check if it meets the required email format (https://jira.sunzinet.com/browse/WIRD-403)
          let account, host, valid
          [ account, host ] = vm.email.split('@')

          valid = /\S+\.\S+/.test(account)
          if (!valid) {
            formValid = false
            vm.formErrors.push({
              field: 'email',
              message: 'Die E-Mail-Adresse entspricht nicht dem erforderlichen Format: vorname.nachname@uk-koeln.de.'
            })
          }
        }
      }

      // check password rules
      if (!vm.password.length) {
        formValid = false
        vm.formErrors.push({
          field: 'password',
          message: 'Passwort ist ein Pflichtfeld.'
        })
      }
      if (!vm.passwordConfirm.length) {
        formValid = false
        vm.formErrors.push({
          field: 'passwordConfirm',
          message: 'Passwort-Wiederholung ist ein Pflichtfeld.'
        })
      }
      if (vm.password.length && vm.passwordConfirm.length && vm.password !== vm.passwordConfirm) {
        formValid = false
        vm.formErrors.push({
          field: 'passwordConfirm',
          message: 'Passwort-Wiederholung stimmt nicht mit Passwort überein.'
        })
      }

      return formValid
    },
    async submit () {
      const vm = (this as any);

      let formValid = vm.validateForm()

      if (formValid) {
        console.log('form valid')
        api.signup(vm.email, vm.password, vm.passwordConfirm)
          .then((result: any) => {
            if (result.status === 201) {
              vm.sent = true;
            } 
          })
          .catch((error: any) => {
            console.log(error);
            vm.sent = true;
            vm.registrationError = true;
          });
      } else {
        console.log('form errors', vm.formErrors)
      }
    }
  },
  ionViewWillLeave () {
    console.log('[ionView] will leave signup');
    (this as any).sent = false;
    (this as any).registrationError = false;
  },
  ionViewDidEnter () {
    console.log('[ionView] entered signup');
  }
}
